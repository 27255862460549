<template>
  <tr>
    <template v-for="(col, idx) in headers">
      <td :key="`tbl.row${index}.col${idx}`" :class="getColumnClass(col)">
        <template v-if="col.value === 'data-table-select'">
          <v-checkbox
            :input-value="isSelected"
            :value="true"
            @change="onSelect"
          />
        </template>

        <template v-else-if="col.value === 'customer_id'">
          <customer-preview :customer="item.customer" />
        </template>

        <template v-else-if="col.value === 'currency'">
          <currency-preview :currency-id="item.currency_id" />
        </template>

        <template v-else-if="col.value === 'currency_convert'">
          <currency-preview :currency-id="item.currency_convert_id" />
        </template>

        <template v-else-if="col.value === 'order_number'">
          <invoice-number-btn :invoice="item" />
        </template>

        <template v-else-if="col.value === 'total_price_value'">
          <span
            class="text-subtitle-1 font-weight-black"
            v-text="getItemValue(col.value)"
          />
        </template>

        <template v-else>
          {{ getItemValue(col.value) }}
        </template>
      </td>
    </template>
  </tr>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import ReportMixin from "@/modules/reports/mixins/ReportMixin";
import CustomerPreview from "@/modules/customers/components/CustomerPreview.vue";
import CurrencyPreview from "@/modules/currencies/components/CurrencyPreview.vue";
import InvoiceNumberBtn from "@/modules/invoices/components/InvoiceNumberBtn.vue";

@Component({
  components: { InvoiceNumberBtn, CurrencyPreview, CustomerPreview },
})
export default class ReportRow extends Mixins(ReportMixin) {}
</script>
